import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  DialogTitle,
  Button,
  DialogActions,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Tabs,
  Tab
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { faExternalLinkAlt, faTimes, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertActions, freshbooksActions } from '../redux/actions';
import { useDispatch } from "react-redux";
import { FreshBooksService } from "../services/freshbooks/freshbbooks.service";
import PaymentButton from "./popupComponents/PaymentButton";
import { CURRENT_USER } from "../constants";
import { jwtDecode } from "jwt-decode";
import ClientSelectionDialog from "./popupComponents/ClientSelectionDialog";

const InvoiceListModal = ({ open, onClose, openCreateInvoice, filteredInvoices, companyId, openCreateSubscription, refreshListInvoice }) => {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem(CURRENT_USER) !== 'undefined' ? JSON.parse(localStorage.getItem(CURRENT_USER)) : {});
  const [invoices, setInvoices] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientUpdated, setClientUpdated] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [saveClientLoading, setSaveClientLoading] = useState(false);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const [isIbanConfigured, setIsIbanConfigured] = useState(false);
  const [clientId, setClientId] = useState(null)
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('TOKEN_USER');
    if (token) {
      const user = jwtDecode(token);
      setCurrentUser(user);
    }
  }, []);

  useEffect(() => {
    fetchClients();
  }, []);


  useEffect(() => {
    const fetchPaymentMethods = async () => {

      const matchingClient = clients.find(
        (client) => client.fbHubspotCompanyId === companyId
      );
      if (matchingClient) {
        setClientId(matchingClient?.id)
        try {
          if (matchingClient.fbPaymentMethodSetupStatus === 1){
            setIsIbanConfigured(true);
          } else {
            const paymentsMethod = await FreshBooksService.getPaymentMethodIbanByClientId(matchingClient.id);
            if (paymentsMethod.length > 0) {
              setIsIbanConfigured(true);
            }
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des paymentsMethodes:", error);
        }
      }
    };

    fetchPaymentMethods();
  }, [clients]);

  const fetchClients = async () => {
    try {
      const fbClients = await FreshBooksService.getAllClients();
      setClients(fbClients);
    } catch (error) {
      console.error("Erreur lors de la récupération des clients:", error);
    }
  };

  useEffect(() => {
    if (filteredInvoices) {
      setInvoices(filteredInvoices);
    }
  }, [filteredInvoices]);

  const [openRow, setOpenRow] = useState(null);
  const handleRowToggle = (invoiceId) => {
    setOpenRow((prev) => (prev === invoiceId ? null : invoiceId)); // Toggle l'état openRow
    setSelectedInvoiceId(invoiceId);
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleSubmitPayment = async () => {
    setPaymentLoading(true);

    if (!selectedInvoiceId || !selectedPaymentMethod) {
      dispatch(alertActions.error("Veuillez sélectionner une facture et un moyen de paiement."));
      setPaymentLoading(false);
      return;
    }

    // Trouver la facture sélectionnée
    const selectedInvoice = invoices?.find(invoice => invoice.id === selectedInvoiceId);

    if (!selectedInvoice) {
      dispatch(alertActions.error("Facture introuvable."));
      setPaymentLoading(false);
      return;
    }

    let totalTTC = 'N/A';
    try {
      const details = JSON.parse(selectedInvoice.fbProductsDetails);
      totalTTC = details?.cost?.totalTTC || 'N/A';
    } catch (e) {
      console.error('Erreur lors du parsing de fbProductsDetails:', e);
      dispatch(alertActions.error("Erreur lors de la récupération du montant total."));
      setPaymentLoading(false);
      return;
    }

    // Créer le paiement et récupérer la liste des factures mises à jour
    try {
      await FreshBooksService.createPayment(selectedPaymentMethod, selectedInvoiceId, totalTTC);
      dispatch(alertActions.success("Paiement effectué avec succès."));

      // Récupérer la liste des factures mises à jour
      const updatedInvoices = await FreshBooksService.getInvoiceListByCompanyId(companyId);
      setInvoices(updatedInvoices);
    } catch (error) {
      console.error("Erreur lors du paiement ou du rafraîchissement des factures:", error);
      dispatch(alertActions.error("Une erreur s'est produite lors du paiement ou du rafraîchissement des factures."));
    } finally {
      setPaymentLoading(false);
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleTabChange = (event, newValue) => {
    refreshListInvoice();
    setSelectedTab(newValue);
  };

  const filteredInvoicesByType = selectedInvoiceType
    ? invoices.filter(invoice => {
      if (selectedInvoiceType === 'impayés') {
        return !invoice?.fbInvoicePayementMode;
      }
      return invoice.fbInvoiceType?.toLowerCase() === selectedInvoiceType?.toLowerCase();
    })
    : invoices;

  const openClientSelectionPopup = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClientSelect = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleSaveClient = async () => {
    if (selectedClient && companyId) {
      setSaveClientLoading(true);
      const data = {
        id: selectedClient,
        fbHubspotCompanyId: companyId
      }
      await FreshBooksService.updateClientsCompanyId(data);
      fetchClients();
      setClientUpdated(true);
      setSaveClientLoading(false);
      setOpenDialog(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 0,
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <DialogTitle align="center">
          <Box
            sx={{
              paddingBottom: 0,
              borderRadius: "4px",
              textAlign: "center",
              textTransform: "uppercase",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1, // Espacement entre les éléments
            }}
          >
            Factures
            {currentUser.profile === 'SUPERADMIN' && (
              <>
                {(() => {
                  const matchingClient = clients.find(
                    (client) => client.fbHubspotCompanyId === companyId
                  );
                  if (matchingClient?.id || clientUpdated) {
                    return (
                      <IconButton
                        aria-label="open-client-freshbooks"
                        onClick={() => {
                          const clientUrl = `https://my.freshbooks.com/#/client/${matchingClient.id}`;
                          window.open(clientUrl, "_blank");
                        }}
                        sx={{
                          color: "primary.main",
                          fontSize: "16px",
                        }}
                        disabled={false}
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </IconButton>
                    );
                  } else {
                    return (
                      <IconButton
                        aria-label="add-client"
                        onClick={() => {
                          // Logique pour afficher un popup de sélection de client
                          openClientSelectionPopup();
                        }}
                        sx={{
                          color: "secondary.main",
                          fontSize: "16px",
                        }}
                      >
                        <FontAwesomeIcon icon={faUserPlus} />
                      </IconButton>
                    );
                  }
                })()}
              </>
            )}

          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </DialogTitle>



        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 1 }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="tabs"
            centered
            sx={{
              '& .MuiTab-root': { minWidth: 150, fontWeight: 600 }
            }}
          >
            <Tab label="Factures" />
            <Tab label="Abonnements" />
          </Tabs>
        </Box>

        <Box sx={{
          pl: 2, pr: 2,
          maxHeight: '60vh',
          overflowY: 'auto',
        }}>
          {selectedTab === 0 && (
            <Box p={1}>
              <FormControl fullWidth>
                <TextField
                  select
                  value={selectedInvoiceType}
                  label="Type de facture"
                  onChange={(e) => setSelectedInvoiceType(e.target.value)}
                  size="small"
                  sx={{ mb: 2, width: '100%' }}
                >
                  <MenuItem value="">Tous</MenuItem>
                  <MenuItem value="Produit">Produits</MenuItem>
                  <MenuItem value="Service">Services</MenuItem>
                  <MenuItem value="impayés">Impayés</MenuItem>
                </TextField>
              </FormControl>
            </Box>
          )}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell sx={{ padding: "4px", textAlign: "left", pr: 3, fontWeight: 600 }} >Numéro</TableCell>
                <TableCell sx={{ padding: "4px", textAlign: "right", pr: 4, fontWeight: 600 }} >Montant&nbsp;(€)</TableCell>
                <TableCell sx={{ padding: "4px", textAlign: "left", pr: 3, fontWeight: 600 }} >Type</TableCell>
                <TableCell sx={{ padding: "4px", textAlign: "left", pr: 3, fontWeight: 600 }} >Statut</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {selectedTab === 0 ? (
                Array.isArray(filteredInvoicesByType) && filteredInvoicesByType.length > 0 ? (
                  filteredInvoicesByType
                    .filter(invoice => invoice.fbInvoiceType?.toLowerCase() !== 'abonnement')
                    .map((invoice) => {
                      const status = invoice.fbInvoicePayementMode || 'Non Payé';
                      let backgroundColor = '';
                      let invoiceNumber = invoice.fbInvoiceNumber;
                      const invoiceType = invoice.fbInvoiceType ? invoice.fbInvoiceType?.toLowerCase() : '';
                      if (invoiceType === 'service') {
                        backgroundColor = 'rgb(255, 160, 160)';
                      } else if (invoiceType === 'produit' || invoiceType === '' || invoiceType === null) {
                        backgroundColor = 'rgb(255, 103, 103)';
                      } else {
                        backgroundColor = 'transparent';
                      }
                      const rowBackgroundColor = status === 'Non Payé'
                        ? backgroundColor
                        : (status !== 'Non Payé'
                          ? (invoiceType === 'produit' || invoiceType === '' || invoiceType === null
                            ? 'rgb(76, 175, 80)' // couleur pour "Produit" ou "null"
                            : 'rgb(120, 200, 120)') // couleur pour autres types de facture
                          : backgroundColor);

                      return (
                        <React.Fragment key={invoice.id}>
                          {/* Ligne principale */}
                          <TableRow
                            onClick={() => handleRowToggle(invoice.id)}
                            style={{ cursor: "pointer", height: "36px", backgroundColor: rowBackgroundColor }}
                          >
                            <TableCell sx={{ padding: "4px" }}>
                              <IconButton size="small">
                                {openRow === invoice.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                              </IconButton>
                            </TableCell>
                            <TableCell style={{ padding: "4px", textAlign: "left" }}>{invoiceNumber}</TableCell>
                            <TableCell sx={{ padding: "4px", paddingRight: 4, textAlign: 'right' }}>
                              {invoice.fbProductsDetails ? (
                                (() => {
                                  try {
                                    const details = JSON.parse(invoice.fbProductsDetails);
                                    const totalHT = details?.cost?.totalHT ?? 0;
                                    const discountValue = details?.cost?.discountValue ?? 0;
                                
                                    return totalHT ? `${totalHT - discountValue} €` : 'N/A';
                                  } catch (e) {
                                      console.error('Erreur lors du parsing de fbProductsDetails:', e);
                                      return 'N/A';
                                  }                                  
                                })()
                              ) : (
                                'N/A'
                              )}
                            </TableCell>
                            <TableCell style={{ padding: "4px", textAlign: "left" }}>{invoice?.fbInvoiceType || 'Produit'}</TableCell>
                            <TableCell sx={{ padding: "4px" }}>
                              {status === 'Non Payé' ? (
                                "Non Payé"
                              ) : (
                                "Payé"
                              )}
                            </TableCell>
                          </TableRow>

                          {/* Contenu du collapse */}
                          <TableRow>
                            <TableCell colSpan={5} style={{ padding: 0 }}>
                              <Collapse in={openRow === invoice.id} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 0 }}>
                                  <Card variant="outlined">
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: 0, padding: 1 }}>
                                      <Grid container direction="row" justifyContent="space-between">
                                        {/* Colonne de gauche : Facture N° et autres informations */}
                                        <Grid item xs={6}>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Facture N° : </strong> {invoiceNumber}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Type : </strong>  {invoice.fbInvoiceType || 'Produit'}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Facturé à : </strong> {invoice.fbBilledTo}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Facturé par : </strong> {invoice.fbHubspotOwner}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Emission : </strong> {new Date(invoice.fbDateOfIssue).toLocaleDateString('fr-FR')}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Echéance : </strong> {new Date(invoice.fbDueDate).toLocaleDateString('fr-FR')}
                                          </Typography>
                                        </Grid>

                                        {/* Colonne de droite : Statut et Totaux */}
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                          {/* Statut à droite */}
                                          <Typography variant="body2" color="textSecondary">
                                            <strong style={{ color: status === 'Non Payé' ? '#d32f2f' : 'green', fontWeight: 'bold' }}>
                                              {status === 'Non Payé' ? 'Non Payé' : 'Payé'}
                                            </strong>
                                          </Typography>

                                          {/* Totaux à droite */}
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Total HT : </strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.totalHT : 'N/A'} €
                                          </Typography>
                                          {invoice.fbProductsDetails && JSON.parse(invoice.fbProductsDetails)?.cost?.discountValue && JSON.parse(invoice.fbProductsDetails)?.cost?.discountValue !== '0.00' ? (
                                            <Typography variant="body2" color="textSecondary">
                                              <strong>Réduction ({JSON.parse(invoice.fbProductsDetails)?.cost?.discount}%) : </strong>
                                              - {JSON.parse(invoice.fbProductsDetails)?.cost?.discountValue} €
                                            </Typography>
                                          ) : null}
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>TVA : </strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.tax : 'N/A'} €
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Total TTC : </strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.totalTTC : 'N/A'} €
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                      {/* Voir la facture */}
                                      <Box sx={{ pt: 1, pb: 1, height: "30px" }}>
                                        <a
                                          href={invoice.fbInvoiceLink}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ textDecoration: "none", width: "100%" }}
                                        >
                                          <Button
                                            variant="outlined"
                                            color="success"
                                            size="small"
                                            fullWidth
                                            sx={{ width: "100%" }}
                                          >
                                            Voir la facture
                                          </Button>
                                        </a>
                                      </Box>

                                      {/* Section à droite : Statut de la facture et formulaire de paiement */}
                                      <Box>
                                        {status === 'Non Payé' && invoice?.fbInvoiceType?.toLowerCase() !== 'abonnement' && (
                                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 3 }}>
                                            <TextField
                                              select
                                              value={selectedPaymentMethod}
                                              onChange={handlePaymentMethodChange}
                                              size="small"
                                              sx={{
                                                flexGrow: 1,
                                                marginRight: 1,
                                              }}
                                              label="Méthode de paiement"
                                            >
                                              <MenuItem value="Check">Paiement par chèque</MenuItem>
                                              <MenuItem value="Cash">Paiement en espèces</MenuItem>
                                            </TextField>

                                            <Button
                                              variant="outlined"
                                              color="success"
                                              onClick={handleSubmitPayment}
                                              size="small"
                                              sx={{
                                                height: "38px",
                                              }}
                                            >
                                              {!paymentLoading ? 'Enregistrer' : <CircularProgress size={13} color="inherit" />}
                                            </Button>
                                          </Box>
                                        )}
                                      </Box>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: 'center', padding: '16px' }}>
                      Aucune facture disponible.
                    </TableCell>
                  </TableRow>
                )
              ) : (
                // Afficher les abonnements 
                Array.isArray(filteredInvoicesByType) && filteredInvoicesByType.length > 0 ? (
                  filteredInvoicesByType
                    .filter(invoice => invoice.fbInvoiceType?.toLowerCase() === 'abonnement')
                    .map((invoice) => {
                      let backgroundColor = '';
                      let invoiceNumber = invoice.fbInvoiceNumber;
                      const invoiceType = invoice.fbInvoiceType ? invoice.fbInvoiceType.toLowerCase() : '';
                      if (invoice.fbVisState === 0 ) {
                        invoiceNumber = String(invoice.fbInvoiceNumber).padStart(11, '0');
                        backgroundColor = 'rgb(40, 167, 69)';
                      } else if(invoice.fbVisState === 2){
                        backgroundColor = 'rgb(255, 193, 7)';
                      } else {
                        backgroundColor = 'rgb(220, 53, 69)';
                      }

                      return (
                        <React.Fragment key={invoice.id}>
                          {/* Ligne principale */}
                          <TableRow
                            onClick={() => handleRowToggle(invoice.id)}
                            style={{ cursor: "pointer", height: "36px", backgroundColor: backgroundColor }}
                          >
                            <TableCell sx={{ padding: "4px" }}>
                              <IconButton size="small">
                                {openRow === invoice.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                              </IconButton>
                            </TableCell>
                            <TableCell style={{ padding: "4px", textAlign: "left" }}>{invoiceNumber}</TableCell>
                            <TableCell sx={{ padding: "4px", paddingRight: 4, textAlign: 'right' }}>
                              {invoice.fbProductsDetails ? (
                                (() => {
                                  try {
                                    const details = JSON.parse(invoice.fbProductsDetails);
                                    const totalHT = details?.cost?.totalHT ?? 0;
                                    const discountValue = details?.cost?.discountValue ?? 0;
                                
                                    return totalHT ? `${totalHT - discountValue} €` : 'N/A';
                                  } catch (e) {
                                      console.error('Erreur lors du parsing de fbProductsDetails:', e);
                                      return 'N/A';
                                  }                                   
                                })()
                              ) : (
                                'N/A'
                              )}
                            </TableCell>
                            <TableCell style={{ padding: "4px", textAlign: "left" }}>{invoice?.fbInvoiceType || 'Produit'}</TableCell>
                            <TableCell sx={{ padding: "4px" }}>
                                {{
                                  0: 'Actif',
                                  2: 'Archivé',
                                  1: 'Supprimé',
                                }[invoice?.fbVisState]}
                            </TableCell>
                          </TableRow>

                          {/* Contenu du collapse */}
                          <TableRow>
                            <TableCell colSpan={5} style={{ padding: 0 }}>
                              <Collapse in={openRow === invoice.id} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 0 }}>
                                  <Card variant="outlined">
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: 0, padding: 1 }}>
                                      <Grid container direction="row" justifyContent="space-between">
                                        {/* Colonne de gauche : Facture N° et autres informations */}
                                        <Grid item xs={7}>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Facture N° : </strong> {invoiceNumber}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Type : </strong>  {invoice.fbInvoiceType || 'Produit'}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Facturé à : </strong> {invoice.fbBilledTo}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Facturé par : </strong> {invoice.fbHubspotOwner}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Date de début : </strong> {new Date(invoice?.createdAt).toLocaleDateString('fr-FR')}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Fréquence d'envoi : </strong>
                                            {{
                                              m: 'Mensuel',
                                              '3m': 'Trimestriel',
                                              '6m': 'Semestriel',
                                              y: 'Annuel',
                                              w: 'Hebdomadaire',
                                            }[invoice?.fbFrequency] || (invoice?.fbFrequency ? invoice?.fbFrequency : 'Aucun')}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Nombre de factures : </strong>
                                            {invoice?.fbNumberRecurring === "0" || !invoice?.fbNumberRecurring
                                              ? 'Infini'
                                              : invoice?.fbNumberRecurring}
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Statut de l'abonnement : </strong>
                                            {{
                                              0: 'Actif',
                                              2: 'Archivé',
                                              1: 'Supprimé',
                                            }[invoice?.fbVisState]}
                                          </Typography>
                                        </Grid>

                                        {/* Colonne de droite : Statut et Totaux */}
                                        <Grid item xs={5} sx={{ textAlign: 'right' }}>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Total HT : </strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.totalHT : 'N/A'} €
                                          </Typography>
                                          {invoice.fbProductsDetails && JSON.parse(invoice.fbProductsDetails)?.cost?.discountValue && JSON.parse(invoice.fbProductsDetails)?.cost?.discountValue !== '0.00' ? (
                                            <Typography variant="body2" color="textSecondary">
                                              <strong>Réduction ({JSON.parse(invoice.fbProductsDetails)?.cost?.discount}%) : </strong>
                                              - {JSON.parse(invoice.fbProductsDetails)?.cost?.discountValue} €
                                            </Typography>
                                          ) : null}
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>TVA : </strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.tax : 'N/A'} €
                                          </Typography>
                                          <Typography variant="body2" color="textSecondary">
                                            <strong>Total TTC : </strong> {invoice.fbProductsDetails ? JSON.parse(invoice.fbProductsDetails)?.cost?.totalTTC : 'N/A'} €
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: 'center', padding: '16px' }}>
                      Aucune abonnement disponible.
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>

          </Table>
        </Box>
        <DialogActions
          sx={{
            paddingY: 2,
            marginY: 0, // Réduire la marge verticale entre les boutons
            justifyContent: 'center',
            gap: 0, // Espacement uniforme entre les boutons
          }}
        >
          {selectedTab === 0 && (
            <Button
              color="error"
              variant="outlined"
              fullWidth
              onClick={() => openCreateInvoice()}
              sx={{
                width: 160, // Réduire la largeur
                fontSize: "0.75rem", // Taille de la police plus petite
                padding: "6px 6px", // Réduction du padding
                marginBottom: 0, // Réduire l'espace sous ce bouton
              }}
            >
              Créer une facture
            </Button>
          )}

          {/* Bouton PaymentButton ajusté */}
          {!isIbanConfigured && <PaymentButton
            onOptionSelect={handleOptionSelect}
            idCompany={companyId}
            clientId={clientId}
          />
          }

          {selectedTab !== 0 && (
            <Button
              variant="outlined"
              color="success"
              fullWidth
              onClick={() => openCreateSubscription()}
              sx={{
                width: 160, // Réduire la largeur
                fontSize: "0.75rem", // Taille de la police plus petite
                padding: "6px 6px", // Réduction du padding
                marginBottom: 0, // Réduire l'espace sous ce bouton
              }}
            >
              Créer abonnement
            </Button>
          )}
        </DialogActions>
        <ClientSelectionDialog
          open={openDialog}
          onClose={handleCloseDialog}
          clients={clients}
          selectedClient={selectedClient}
          onSelectClient={handleClientSelect}
          onSaveClient={handleSaveClient}
          loading={saveClientLoading}
        />
      </Box>
    </Modal>
  );
};

export default InvoiceListModal;
